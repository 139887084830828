// import { useEffect, useState } from 'react';
// import {
//     AuthenticatedTemplate,
//     UnauthenticatedTemplate,
//     useMsal,
//     useAccount,
// } from '@azure/msal-react';
// import { useSelector, useDispatch } from 'react-redux';
// import { loginRequest } from './auth.config';

// const Pages = () => {
//     const { instance, accounts } = useMsal();
//     const activeAccount = instance.getActiveAccount();
//     const account = useAccount(accounts[0] || {});
//     const user = useSelector((state) => state?.auth?.user);

//     async function callHandleRedirectPromise(instanceObj) {
//         await instanceObj.handleRedirectPromise;
//     }
//     useEffect(() => {
//         callHandleRedirectPromise(instance);

//         if (account) {
//             localStorage.setItem('AccessToken', account.idToken);
//             if (!user) {
//             }
//         }
//     }, [account, instance]);

//     const handleRedirect = () => {
//         instance
//             .loginRedirect({ ...loginRequest, prompt: 'create' })
//             .catch((error) => console.error(error));
//     };

//     const handleLogin = () => {
//         // Replace with the URL of your existing application with authentication
//         const existingAppUrl =
//             'https://coss-int.daimlertruck.com/coss/pages/root/home.html';
//         // Optionally, pass parameters or state like tokens, etc.
//         // Redirecting to the login URL of the existing application
//         window.location.href = existingAppUrl;
//     };

//     return (
//         <div>
//             <UnauthenticatedTemplate>
//                 {activeAccount?.idToken !== undefined ? null : handleRedirect()}
//             </UnauthenticatedTemplate>
//             <AuthenticatedTemplate>
//                 {account ? (
//                     <div>
//                         <h1>COSS</h1>
//                         <button onClick={handleLogin}>Redirect</button>
//                     </div>
//                 ) : (
//                     <h1>
//                         {
//                             "You don't have permission to access media hub. Please contact the admin"
//                         }
//                     </h1>
//                 )}
//             </AuthenticatedTemplate>
//         </div>
//     );
// };

// export default Pages;






// import { useEffect } from 'react';
// import {
//     AuthenticatedTemplate,
//     UnauthenticatedTemplate,
//     useMsal,
//     useAccount,
// } from '@azure/msal-react';
// import { useSelector } from 'react-redux';
// import { loginRequest } from './auth.config';

// const Pages = () => {
//     const { instance, accounts } = useMsal();
//     const activeAccount = instance.getActiveAccount();
//     const account = useAccount(accounts[0] || {});
//     const user = useSelector((state) => state?.auth?.user);

//     // Properly handle the redirect promise
//     useEffect(() => {
//         const handleRedirectPromise = async () => {
//             try {
//                 const response = await instance.handleRedirectPromise();
//                 if (response) {
//                     instance.setActiveAccount(response.account);
//                 }
//             } catch (error) {
//                 console.error("Error handling redirect promise", error);
//             }
//         };
//         handleRedirectPromise();
//     }, [instance]);

//     // Move handleRedirect to useEffect to prevent it from running during render
//     useEffect(() => {
//         if (!activeAccount?.idToken) {
//             instance
//                 .loginRedirect({ ...loginRequest, prompt: 'create' })
//                 .catch((error) => console.error(error));
//         }
//     }, [activeAccount, instance]);


//     return (
//         <div>
//             <UnauthenticatedTemplate>
//                 {/* No need to check and call handleRedirect inside render */}
//                 {activeAccount?.idToken === undefined && (
//                     <p>Redirecting to login...</p>
//                 )}
//             </UnauthenticatedTemplate>
//             <AuthenticatedTemplate>
//                 {account ? (
//                     <div>
//                         <h1>Welcome to the COSS application</h1>
//                     </div>
//                 ) : (
//                     <h1>
//                         {"You don't have permission to access COSS. Please contact the admin"}
//                     </h1>
//                 )}
//             </AuthenticatedTemplate>
//         </div>
//     );
// };

// export default Pages;



import { useEffect, useState } from 'react';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useAccount,
} from '@azure/msal-react';
// import { useSelector, useDispatch } from 'react-redux';
// import Router from './Router';
import { loginRequest } from './auth.config';
// import { getUserInfo } from './Services/UserService';
// import { login } from './Store/authSlice';
// import { setCurrentRole } from './Store/roleSlice';

const Pages = () => {
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const account = useAccount(accounts[0] || {});
    // const dispatch = useDispatch();
    // const user = useSelector((state) => state?.auth?.user);
    // const [unauthorized, setUnauthorized] = useState(false);
    // const [forbidden, setForbidden] = useState(false);

    async function callHandleRedirectPromise(instanceObj) {
        await instanceObj.handleRedirectPromise;
    }
    useEffect(() => {
        callHandleRedirectPromise(instance);

        if (account) {
            localStorage.setItem('AccessToken', account.idToken);
            // if (!user) {
            // 	getUserInfo()
            // 		.then((userInfo) => {
            // 			dispatch(login(userInfo));
            // 			const currentRole = userInfo?.userRole;
            // 			dispatch(setCurrentRole(currentRole));
            // 		})
            // 		.catch((err) => {
            // 			console.warn(err);
            // 			if (err?.response?.status === 401) {
            // 				// setUnauthorized(true);
            // 			} else if (err?.response?.status === 403) {
            // 				setForbidden(true);
            // 			}
            // 		});
            // }
        }
    }, [account, instance]);

    const handleRedirect = () => {
        instance
            .loginRedirect({ ...loginRequest, prompt: 'login' })
            .catch((error) => console.error(error));
    };

    return (
        <div>
            <UnauthenticatedTemplate>
                {activeAccount?.idToken !== undefined ? null : handleRedirect()}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {account ? (
                    <div>
                        <h1>Welcome to the COSS application</h1>
                    </div>
                ) : (
                    <h1>
                        {
                            "You don't have permission to access media hub. Please contact the admin"
                        }
                    </h1>
                )}
            </AuthenticatedTemplate>
        </div>
    );
};

export default Pages;