import './App.css';

import { MsalProvider } from '@azure/msal-react';
import Pages from './Pages';

function App({ instance }) {
	console.log(process.env.REACT_APP_azure_tenant_id)
	return (
		<MsalProvider instance={instance}>
			<Pages />
		</MsalProvider>
	);
}

export default App;